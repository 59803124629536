/* adyen */
.adyen-drop-in-container {
  & .adyen-checkout__payment-method {
    border: 0;
  }

  & .adyen-checkout__payment-method--selected {
    border: 0;
    background: transparent;
  }

  & .adyen-checkout__button {
    background: var(--color-primary);

    &:hover {
      background: var(--color-primary-dark);
    }
  }

  & .adyen-checkout__payment-method__radio--selected {
    background: var(--color-primary);
  }

  & .adyen-checkout__payment-method__radio {
    left: 0;
  }

  & .adyen-checkout__payment-method__details {
    padding: 0;
  }

  & .adyen-checkout__payment-method__header {
    padding-left: 32px;
  }
}
