:root {
  --hero-aspect: 5 / 6;
  @media (--small) {
    --hero-aspect: 16 / 9;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  overflow: auto;
  pointer-events: auto !important;
  margin: 0;
  background: var(--color-background);

  &::after {
    content: "";
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    transition:
      background 300ms,
      width 0ms 150ms,
      height 0ms 150ms;
    background: rgba(0, 0, 0, 0);
  }

  &.scroll-locked {
    overflow: hidden;
  }

  &.scroll-locked,
  &.overlay {
    &::after {
      z-index: var(--z-overlay);
      width: 100%;
      height: 100%;
      transition: background 300ms;
      background: rgba(0, 0, 0, 0.7);
    }
  }

  /* Hubspot Cookie Consent - Set body overflow: hidden when cookie consent is displaying */
  &:has(#hs-eu-cookie-confirmation:not(.hs-hidden)) {
    overflow: hidden;

    &::after {
      z-index: var(--z-overlay);
      width: 100%;
      height: 100%;
      transition: background 300ms;
      background: rgba(0, 0, 0, 0.7);
    }

    & #navbarWrapper {
      z-index: var(--z-behind);
    }
  }
}

html {
  scrollbar-gutter: stable;
}

#coiOverlay {
  z-index: 999999;
}
