/*
 * data-layout=page for full width elements
 * data-layout=section for inset elements
 * data-blok on the outer wrapper of all bloks
 */

:root {
  --section-margin: 80px;
  --content-margin: 50px;
  --stacked-margin: var(--margin-spacing);

  --page-inset: var(--spacing-16); /* General site horizontal margins */
  --smart-page-inset: var(--page-inset);

  --page-inset-width: min(
    var(--page-width),
    calc(100% - (var(--page-inset) * 2))
  );
  --section-inset-width: min(
    var(--section-width),
    calc(100% - (var(--page-inset) * 2))
  );
  --content-inset-width: var(--page-inset-width);
  --text-inset-width: min(
    var(--text-width),
    calc(100% - (var(--page-inset) * 2))
  );
  @media (--small) {
    --section-margin: 120px;
    --smart-page-inset: max(
      calc((100vw - var(--page-width)) / 2 - 8px),
      var(--page-inset)
    );
  }

  @media (--large) {
    --content-inset-width: calc(var(--page-inset-width) * 0.9);
  }

  --column: calc(100% / 12);
}

[data-layout="page"] {
  max-width: var(--page-inset-width);
  width: 100%;
  margin: 0 auto;

  & > [data-layout="section"] {
    max-width: var(--section-width);
  }

  .modal & {
    max-width: 100%;
  }
}

[data-layout="page"] [data-layout="page"] {
  max-width: var(--page-width);
}

[data-layout="content"] {
  max-width: var(--content-inset-width);
  margin: 0 auto;
}

[data-layout="text"] {
  max-width: var(--text-inset-width);
  margin: 0 auto;
}

[data-layout="full"] {
  max-width: 100%;
  margin: 0 auto;
}

[data-blok] + [data-blok] {
  margin-top: var(--section-margin);
}

[data-blok][data-editorial="true"] + [data-blok][data-editorial="true"] {
  margin-top: var(--content-margin);
}

.navigation-bar + [data-blok]:not([data-blok-background][data-layout="full"]) {
  margin-top: var(--section-margin);
}

.inset {
  padding-left: var(--page-inset);
  padding-right: var(--page-inset);
}

img {
  max-width: 100%;
  height: auto;
}

.divider {
  border-bottom: 1px solid var(--color-divider);
}

.layout-simple {
  --grid-left-indent: 6 span;
  --grid-left-indent-small: 6 span;

  --grid-right-indent: 6 / 12;
  --grid-right-indent-small: 6 / 12;

  --section-margin: 80px;
}
